<template>
  <div class="conditionsUtilisation" >
    <div class="container">
      <div class="row text-center mt-4">
        <div class="col-12">
          <h2 class="help-title">Conditions générales d'utilisation du Site</h2>
          <em> En vigueur au 2 septembre 2020 </em>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <h3 class="help-subtitle">
            ARTICLE 1 : Objet
          </h3>
          <p>
            Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet
            l'encadrement juridique des modalités de mise à disposition des services de la
            plateforme situé https://garde-enfant-fonpeps-audiens.org (dénommés "Le Site") et de
            définir les conditions d’accès et d’usage par « l'Utilisateur ».<br>
            Les présentes CGU sont accessibles sur le Site à la rubrique "Conditions générales
            d'utilisations".
          </p>
          <p>
            Toute inscription ou utilisation du Site implique l'acceptation sans aucune réserve ni
            restriction des présentes CGU par l’Utilisateur.
          </p>
          <p>
            Lors de l'inscription sur le Site via le Formulaire d’inscription, chaque Utilisateur
            accepte expressément les présentes CGU en cochant la case précédant le texte
            suivant : <em>« Je certifie avoir lu et accepté les Conditions Générales d’Utilisation et la Politique de Confidentialité. »</em>
          </p>
          <p>
            En cas de non-acceptation des CGU stipulées dans le présent contrat, l'Utilisateur se
            doit de renoncer à l'accès des services proposés par le Site.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            ARTICLE 2 : Mentions légales
          </h3>
          <p>
            L'édition du Site est assurée par
            la Société GEEGZ, SAS, au capital de 1 000€, immatriculée au RCS de Paris sous le numéro
            883 531 105 dont le siège social est situé au 18, passage du chantier - 75012 Paris, sous la commande du
            Groupe Audiens, association régie par la loi de 1901, immatriculée au Répertoire SIRENE sous le
            numéro 448 323 758, dont le siège social se situe 74 rue Jean Bleuzen - 92170 Vanves. <br>
            Le Directeur de la publication est Isabelle Thirion, Directrice Développement social et Individus. <br>
            L'hébergeur du Site est la Société OVH SAS,
            au capital de 10 069 020 €, immatriculée au RCS de Lille Métropole sous le numéro 424 761 419,
            dont le siège social est situé au 2, rue Kellermann - 59100 Roubaix, France.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            ARTICLE 3 : Définitions
          </h3>
          <p>
            Dans le cadre des présentes, il est acquis les définitions des termes essentiels tels que
            ci-après exprimés s’opposent à tout Utilisateur.
          </p>
          <p>
            <strong>Utilisateur</strong> : ce terme désigne toute personne qui utilise le Site (les services proposés par la plateforme).
          </p>
          <p>
            <strong>Contenu Utilisateur</strong> : ce sont les données transmises par l’Utilisateur au sein du Site.
          </p>
          <p>
            <strong>Membre</strong> : l’Utilisateur devient membre lorsqu’il s’inscrit et est identifié sur le Site.
          </p>
          <p>
            <strong>Identifiant et mot de passe</strong> : c’est l’ensemble des informations nécessaires à
            l’identification d’un Utilisateur sur le Site :
            <ul>
              <li>L’identifiant est composé par l’adresse électronique de l’Utilisateur (email),</li>
              <li>Le mot de passe est généré par le Site ou librement créé par l’Utilisateur. Il est
              confidentiel.</li>
            </ul>
          </p>
          <p>
            L’identifiant et le mot de passe permettent à l’Utilisateur d’accéder aux services
            réservés aux membres.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            ARTICLE 4 : Accès au Site
          </h3>
          <p>
            L'utilisation du Site est destinée aux artistes et techniciens du spectacle.
          </p>
          <p>
            L’utilisation du Site vise à solliciter une aide financière d'aide à la garde d'enfant auprès
            d’Audiens et du FONPEPS.
          </p>
          <p>
            Le Site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à
            Internet. Il permet un accès gratuit aux services suivants :
              <ul>
                <li> La simulation du test d'admissibilité au dépôt de la demande d'aide financière,</li>
                <li> La création d'un espace personnel,</li>
                <li> L'échange d'informations et de documents permettant à un conseiller Audiens d'instruire le dossier,</li>
                <li> La récupération de l'information sur le montant de l’aide allouée pour chaque demande de remboursement.</li>
              </ul>
          </p>
          <p>
            Tous les frais supportés par l'Utilisateur pour accéder au service (matériel
            informatique, logiciels, connexion Internet, etc.) sont à sa charge
          </p>
          <p>
            L’Utilisateur non membre n'a pas accès aux services réservés. Pour cela, il doit
            s’inscrire en remplissant le formulaire. En acceptant de s’inscrire aux services réservés,
            l’Utilisateur membre s’engage à fournir des informations sincères et exactes
            concernant son état civil et ses coordonnées, notamment son adresse email.
            L’Utilisateur est responsable de la mise à jour des informations fournies. Il lui est
            précisé qu’il peut les modifier à tout moment en se connectant à son espace
            personnel.
          </p>
          <p>
            Pour accéder aux services, l’Utilisateur devra s'identifier à l'aide de son email et de
            son mot de passe qu'il définit lors de son inscription et qui sont strictement
            personnels. A ce titre, il s’en interdit toute divulgation. Dans le cas contraire, il restera
            seul responsable de l’usage qui en sera fait.
          </p>
          <p>
            L’Utilisateur pourra également solliciter sa désinscription en envoyant un email à :
            gardenfant@audiens.org. Celle-ci sera effective dans un délai
            raisonnable. En cas de non-respect des CGU, le Site aura la possibilité de suspendre
            voire de clôturer le compte d’un Utilisateur après mise en demeure adressée par voie
            électronique et restée sans effet. Toute suppression de compte, quel qu’en soit le
            motif, engendre la suppression pure et simple de toutes informations personnelles de
            l’Utilisateur, après expiration du délai de conservation applicable. Tout événement dû
            à un cas de force majeure ayant pour conséquence un dysfonctionnement du Site ou
            serveur et sous réserve de toute interruption ou modification en cas de maintenance,
            n'engage pas la responsabilité du Site. Dans ces cas, l’Utilisateur accepte ainsi ne pas
            tenir rigueur à l’éditeur de toute interruption ou suspension de service, même sans
            préavis. L'Utilisateur a la possibilité de contacter le Site par messagerie électronique à
            l’adresse : gardenfant@audiens.org
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            ARTICLE 5 : Données personnelles
          </h3>
          <p>
            L’éditeur, soucieux de respecter la réglementation relative à la protection des
            données à caractère personnel (Règlement Général sur la Protection des Données ou
            RGPD, et Loi Informatique et Libertés) s’engage à collecter et traiter les données
            personnelles de l’Utilisateur de façon loyale et transparente.
          </p>
          <p>
            L’éditeur recueille des données à caractère personnel :
            <ul>
              <li>dans le cadre de la gestion du dossier d’aide financière,</li>
              <li>et pour la gestion des espaces personnalisés.</li>
            </ul>
          </p>
          <p>
            Ces informations ne sont recueillies par l’éditeur qu'à la suite de leur communication
            volontaire par l’Utilisateur. Elles sont susceptibles d’être transmises à l’hébergeur du
            présent Site.
          </p>
          <p>
            L'Utilisateur peut accéder à la politique de confidentialité de l’éditeur sur la page
            Politique de Confidentialité.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            ARTICLE 6 : Propriété intellectuelle
          </h3>
          <p>
            Les marques, logos, signes ainsi que tous les contenus du Site font l'objet d'une
            protection par le Code de la propriété intellectuelle et plus particulièrement par le
            droit d'auteur. La marque Audiens est une marque déposée. Toute représentation
            et/ou reproduction et/ou exploitation partielle ou totale de cette marque, de quelque
            nature que ce soit, est totalement prohibée. L'Utilisateur doit solliciter l'autorisation
            préalable du Site pour toute reproduction, publication, copie des différents contenus.
            Il s'engage à une utilisation des contenus du Site dans un cadre strictement privé,
            toute utilisation à des fins commerciales et publicitaires est strictement interdite.
            Toute représentation totale ou partielle de ce Site par quelque procédé que ce soit,
            sans l’autorisation expresse de l’exploitant du Site constituerait une contrefaçon
            sanctionnée par l’article L.335-2 et suivants du Code de la propriété intellectuelle. Il
            est rappelé conformément à l’article L.122-5 du Code de propriété intellectuelle que
            l’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa
            source.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            ARTICLE 7 : Responsabilité
          </h3>
          <p>
            Les sources des informations diffusées sur le Site sont réputées fiables mais le Site ne
            garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions. Les informations
            communiquées sont présentées à titre indicatif et général sans valeur contractuelle.
            Malgré des mises à jour régulières, le Site ne peut être tenu responsable de la
            modification des dispositions administratives et juridiques survenant après la
            publication. De même, le Site ne peut être tenu responsable de l’utilisation et de
            l’interprétation de l’information contenue dans ce Site.
          </p>
          <p>
            L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot de
            passe, quelle que soit sa forme, est interdite. Il assume les risques liés à l'utilisation de
            son email et de son mot de passe. Le Site décline toute responsabilité. Le Site ne peut
            être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur ou
            tout matériel informatique de l’Utilisateur, suite à une utilisation, à l’accès, ou au
            téléchargement provenant de ce Site. La responsabilité du Site ne peut être engagée
            en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            ARTICLE 8 : Liens hypertextes
          </h3>
          <p>
            Des liens hypertextes peuvent être présents sur le Site. L’Utilisateur est informé qu’en
            cliquant sur ces liens, il sortira du Site. L’éditeur ne les édite pas et ne les contrôle pas.
            Les liens vers tous Sites ne constituent, en aucun cas, une approbation ou un
            partenariat entre l’éditeur et ceux-ci. Dès lors, l’éditeur ne saurait être responsable de
            leurs contenus, produits, publicités ou de tous éléments ou services présentés sur ces
            Sites. Ces derniers sont soumis à leurs propres conditions d'utilisation et de
            protection des données personnelles.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            ARTICLE 9 : Cookies
          </h3>
          <p>
            Ce Site web stocke certaines informations fournies par l'Utilisateur en local. A aucun
            moment, les données stockées sur le navigateur de l'Utilisateur ne sont envoyées au
            serveur sans son accord (lorsque l'Utilisateur choisit d'envoyer ses données au
            serveur en cliquant le bouton à cet effet). Ces données sont stockées pour permettre
            l'utilisation du Site par l'Utilisateur :
            <ul>
              <li>
                L’ID de session permettant à l’Utilisateur de rester connecté sur le Site. Par
                mesure de sécurité, ces cookies sont inutilisables au bout de sept (7) jours et
                l’Utilisateur devra se reconnecter
              </li>
              <li>
                Le profil d'Utilisateur pour adapter et personnaliser le Site à ses besoins
              </li>
            </ul>
            Ces données stockées ne sont jamais envoyées vers le serveur sans le consentement
            de l'Utilisateur et ne sont pas utilisées pour tracker l'Utilisateur. Par ailleurs,
            l'Utilisateur peut supprimer les données stockées sur son navigateur à tout moment
            en passant par les options.
          </p>
          <p>
            Le Site utilise des cookies uniquement dans un objectif de mesure d'audience, via l'outil Google Analytics.
          </p>
          <p>
            L’Utilisateur peut s’opposer à l’enregistrement des cookies en configurant son
            navigateur en cliquant sur l’un des liens suivants :
            <ul>
              <li>Pour Internet Explorer™ : <a href="http://windows.microsoft.com/fr-FR/windows-vista/Blockor-allow-cookies" target="_blank">http://windows.microsoft.com/fr-FR/windows-vista/Blockor-allow-cookies</a></li>
              <li>Pour Safari™ : <a href="http://support.apple.com/kb/ht1677" target="_blank">http://support.apple.com/kb/ht1677</a></li>
              <li>Pour Chrome™ : <a href="http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647" target="_blank">http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647</a>,</li>
              <li>Pour Firefox™ : <a href="http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies" target="_blank">http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies</a></li>
              <li>Pour Opera™ : <a href="http://help.opera.com/Windows/10.20/fr/cookies.html" target="_blank">http://help.opera.com/Windows/10.20/fr/cookies.html</a></li>
            </ul>
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            ARTICLE 10 : Envoi de document par l'Utilisateur
          </h3>
          <p>
            Le Site permet à ses membres d’importer des documents et de communiquer des
            informations. L'Utilisateur permet à la société de les traiter dans le but d'instruire son
            dossier. Tout contenu importé par l'Utilisateur est de sa seule responsabilité.
            L'Utilisateur s'engage à ne pas importer de contenus pouvant porter atteinte aux
            intérêts de tierces personnes. Tout recours en justice engagé par un tiers lésé contre
            le Site sera pris en charge par l'Utilisateur.
          </p>
          <p>
            Le contenu de l'Utilisateur peut être à tout moment et pour n'importe quelle raison
            supprimé ou modifié par le Site, sans préavis.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            ARTICLE 11 : Durée - Evolution du Contrat
          </h3>
          <p>
            La durée des présentes CGU est indéterminée. Les présentes produisent leurs effets à
            l’égard de l’Utilisateur, à compter de la première utilisation du service jusqu’à la
            cessation de l’accès au Site.
            Le Site se réserve le droit de modifier unilatéralement et à tout moment le contenu
            des CGU.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3 class="help-subtitle">
            ARTICLE 12 : Droit applicable
          </h3>
          <p>
            L'accès au présent Site, sa consultation et son utilisation, sont régis par le droit
            français.
            Pour toute question relative à l’application des présentes CGU, vous pouvez joindre
            l’éditeur au mail gardenfant@audiens.org
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ConditionsUtilisation',
}
</script>
